import { Component, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";

@Component({
  components: {
    DfSectionDigitalPublication: () => Utils.externalComponent2("df-section-digital-publication"),
  },
})
export default class DfPagePdfComponent extends Vue {

}
